const schedule = [
    {
      "activity": "iosconfsg22.workshop1",
      "start_at": "2022-01-17T05:20:00+00:00",
      "end_at": "2022-01-17T05:50:00+00:00",
      "id": 1,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "Check-in 💻 - Find your favorite Zoom's video filter for photo session!"
    },
    {
      "activity": "iosconfsg22.workshop1",
      "start_at": "2022-01-17T05:50:00+00:00",
      "end_at": "2022-01-17T06:00:00+00:00",
      "id": 2,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "Opening Address 🎤"
    },
    {
      "activity": "iosconfsg22.workshop1",
      "start_at": "2022-01-17T06:00:00+00:00",
      "end_at": "2022-01-17T08:00:00+00:00",
      "id": 3,
			"speaker_name": "Sven Schleier",
			"speaker_company": "F-Secure",
			"speaker_image": "sven",    
			"speaker_twitter": "bsd_daemon",
			"speaker_bio": "Sven is the Technical Director of F-Secure, Singapore and has hands-on experience in attacking and defending web and mobile apps for the last 10+ years. He will conduct a workshop on Finding vulnerabilities in iOS Apps.",          
      "talk_description": "In this session, Sven will cover Basic Threat Modeling, Testing for sensitive data in iOS apps. He will then demonstrate how to scan for secrets in a Swift project using the open-source tool `gitleaks` via Github Actions.",
      "talk_type": "workshop",
      "title": "Workshop: Find Vulnerabilities in iOS Apps - Session 1"
    },
    {
      "activity": "iosconfsg22.workshop1",
      "start_at": "2022-01-17T08:00:00+00:00",
      "end_at": "2022-01-17T08:30:00+00:00",
      "id": 4,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "15 minutes break",
      "talk_type": "shortbreak",
      "title": "Break ☕ 🚻 💬"
    },
    {
      "activity": "iosconfsg22.workshop1",
      "start_at": "2022-01-17T08:30:00+00:00",
      "end_at": "2022-01-17T10:30:00+00:00",
      "id": 3,
			"speaker_name": "Sven Schleier",
			"speaker_company": "F-Secure",
			"speaker_image": "sven",    
			"speaker_twitter": "bsd_daemon",
			"speaker_bio": "Sven is the Technical Director of F-Secure, Singapore and has hands-on experience in attacking and defending web and mobile apps for the last 10+ years. He will conduct a workshop on Finding vulnerabilities in iOS Apps.",          
      "talk_description": "In this session, Sven will dive into static scanning of swift code and how you can execute a static scan against Swift Code to detect vulnerabilities using several open-source tools. This session will also cover analysing App Transport Security (ATS). Sven will end the workshop by teaching how to scan the third libraries you are using that may have known vulnerabilities.",
      "talk_type": "workshop",
      "title": "Workshop: Find Vulnerabilities in iOS Apps - Session 2"
    },
    {
      "activity": "iosconfsg22.workshop2",
      "start_at": "2022-01-18T00:20:00+00:00",
      "end_at": "2022-01-18T00:50:00+00:00",
      "id": 6,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "Check-in 💻"
    },
    {
      "activity": "iosconfsg22.workshop2",
      "start_at": "2022-01-18T00:50:00+00:00",
      "end_at": "2022-01-18T01:00:00+00:00",
      "id": 7,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "Opening Address 🎤"
    },
    {
      "activity": "iosconfsg22.workshop2",
      "start_at": "2022-01-18T01:00:00+00:00",
      "end_at": "2022-01-18T03:00:00+00:00",
      "id": 8,
      "speaker_name": "Malin Sundberg",
			"speaker_company": "Orbit",
			"speaker_image": "malin",    
			"speaker_twitter": "malinsundberg",
			"speaker_bio": "Malin is an iOS and macOS developer who decided to go full in on SwiftUI when creating her app Orbit for the Mac, iPhone, and iPad. She will conduct a workshop on Multi-platform Development with SwiftUI.",          
      "talk_description": "In this workshop, we'll use SwiftUI to build multi-platform apps while focusing on what makes each platform unique. In the first session, we’ll be building an iOS app with custom UI components, while going through different ways of structuring our views to make them reusable, composable, customizable, and easy to maintain. We will also run through some general SwiftUI tips and tricks.",
      "talk_type": "workshop",
      "title": "Workshop: Multi-platform Development with SwiftUI - Session 1"
    },
    {
      "activity": "iosconfsg22.workshop2",
      "start_at": "2022-01-18T03:00:00+00:00",
      "end_at": "2022-01-18T03:30:00+00:00",
      "id": 9,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "15 minutes break",
      "talk_type": "shortbreak",
      "title": "Break ☕ 🚻 💬"
    },
    {
      "activity": "iosconfsg22.workshop2",
      "start_at": "2022-01-18T03:30:00+00:00",
      "end_at": "2022-01-18T05:30:00+00:00",
      "id": 10,
      "speaker_name": "Malin Sundberg",
			"speaker_company": "Orbit",
			"speaker_image": "malin",    
			"speaker_twitter": "malinsundberg",
			"speaker_bio": "Malin is an iOS and macOS developer who decided to go full in on SwiftUI when creating her app Orbit for the Mac, iPhone, and iPad. She will conduct a workshop on Multi-platform Development with SwiftUI.",          
      "talk_description": "In the second session, we’ll be moving our app onto a new platform, macOS. We’ll adapt the app’s navigational flows, views, and behaviours to make it feel at home on the new platform. We will also run through ways to combine SwiftUI and AppKit, and some common scenarios where this comes in handy.",
      "talk_type": "workshop",
      "title": "Workshop: Multi-platform Development with SwiftUI - Session 2"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T00:00:00+00:00",
      "end_at": "2022-01-20T00:30:00+00:00",
      "id": 11,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "Welcome to iOS Conf SG 2021 🎉",
      "talk_type": "registration",
      "title": "Check-in 💻"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T00:30:00+00:00",
      "end_at": "2022-01-20T00:45:00+00:00",
      "id": 12,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "Morning Boost ⚡️ - say Hi to everyone  👩🏻‍💻🧑🏽‍💻🧑🏾‍💻👩🏼‍💻, don't be shy!"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T00:45:00+00:00",
      "end_at": "2022-01-20T01:00:00+00:00",
      "id": 13,
      "speaker_bio": "Writer ✍🏽 Developer 👨🏽‍💻 Organiser of @iOSConfSG 🍎🇸🇬",
      "speaker_company": "",
      "speaker_image": "subh",
      "speaker_name": "Subhransu Behera",
      "speaker_twitter": "subhransu",
      "talk_description": "Welcome to the 7th Edition of iOS Conf SG.",
      "talk_type": "openingAddress",
      "title": "Opening address"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T01:00:00+00:00",
      "end_at": "2022-01-20T01:35:00+00:00",
      "id": 14,
      "speaker_bio": "Daniel is the author of more than a dozen books including the best selling books A Functional Programming Kickstart, A SwiftUI Kickstart, A Swift Kickstart and Dear Elena. He presents iOS, Functional Programming, SwiftUI, and Swift training and consults through his company Dim Sum Thinking.",
			"speaker_company": "Dim Sum Thinking",
			"speaker_image": "daniel",
			"speaker_name": "Daniel H Steinberg",
			"speaker_twitter": "dimsumthinking",
			"talk_description": "The new Swift async/await syntax is so nicely implemented that we might be tempted to ignore that there is asynchronous magic right in the middle of an expression. In this talk we provide a mental model for thinking about asynchronous execution that will make asynchronous code easier to write and easier to reason about.",
			"talk_type": "normalTalk",
			"title": "Willing Suspension of Disbelief"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T01:35:00+00:00",
      "end_at": "2022-01-20T02:10:00+00:00",
      "id": 15,
      "speaker_bio": "Frank is the chief instructor and content creator at School of Swift, helping teams skill up their iOS development.",
      "speaker_company": "School of Swift",
      "speaker_image": "frank",
      "speaker_name": "Frank Courville",
      "speaker_twitter": "Frankacy",
      "talk_description": "Have you ever opened a large code file and wondered what exactly is going on? Often, state machines can help with this very problem! In this session, you’ll learn all about state machines and how you can use them to extract complicated logic, making our components easier to use and test.",
      "talk_type": "normalTalk",
      "title": "Leveraging the Power of State Machines in Swift"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T02:10:00+00:00",
      "end_at": "2022-01-20T02:20:00+00:00",
      "id": 16,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "10 minutes discussion",
      "talk_type": "shortbreak",
      "title": "Developer's Discussions 💬, you'll be magically transported into smaller pods to get to know each other."
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T02:20:00+00:00",
      "end_at": "2022-01-20T02:30:00+00:00",
      "id": 17,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "10 minutes break",
      "talk_type": "shortbreak",
      "title": "Short Break ☕ 🚻 💬"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T02:30:00+00:00",
      "end_at": "2022-01-20T02:40:00+00:00",
      "id": 18,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "Inspiration Boost ⚡️"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T02:40:00+00:00",
      "end_at": "2022-01-20T03:15:00+00:00",
      "id": 19,
      "speaker_bio": "Vivian is an ios engineer at instagram working on feed, interactions, and creation. in her free time, she likes baking desserts, trying new foods, and organizing events!",
      "speaker_company": "Instagram",
      "speaker_image": "vivian",
      "speaker_name": "Vivian Phung",
      "speaker_twitter": "vivianphung",
      "talk_description": "Since IGListKits inception, developers at Instagram have been using it throughout the app - feed, explore, stories, and more. In this talk, Vivian will share the newest updates and why you should consider it.",
      "talk_type": "normalTalk",
      "title": "IGListKit in 2022"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T03:15:00+00:00",
      "end_at": "2022-01-20T03:50:00+00:00",
      "id": 20,
      "speaker_bio": "Tee is a self taught iOS developer who started in accounting but landed in tech. In her freetime, you can find her boxing, practicing muay thai, working on new jumprope tricks or just working out.",
      "speaker_company": "Rocket Mortgage",
      "speaker_image": "ting",
      "speaker_name": "Ting Becker",
      "speaker_twitter": "Teekachu1",
      "talk_description": "A tale of quitting my 6-figure accounting career and professional license to switch into an entirely new field in the midst of a pandemic. Come along the journey of me navigating through imposter syndrome, feeling behind as I approach self-learning programming with a total beginner's mind, and landing my first full time software role 8 months later.",
      "talk_type": "normalTalk",
      "title": "How Falling Behind Actually Got Me Ahead"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T03:50:00+00:00",
      "end_at": "2022-01-20T04:40:00+00:00",
      "id": 22,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "shortbreak",
      "title": "📸 Photo session to make history, pick a Zoom's video filter! then grab a Bite 🍽 (50 Mins Break)"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T04:40:00+00:00",
      "end_at": "2022-01-20T04:50:00+00:00",
      "id": 23,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "Quiz and Games",
      "talk_type": "energyboost",
      "title": "Kahoot time⚡️! Watch out for the game pin and win prizes!"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T04:50:00+00:00",
      "end_at": "2022-01-20T05:25:00+00:00",
      "id": 24,
      "speaker_bio": "Danijela is an iOS Developer and an article author for raywenderlich.com. In her free time, you can find her hiking or lifting heavy weights at the gym.",
      "speaker_company": "Deloitte Canada",
      "speaker_image": "danijela",
      "speaker_name": "Danijela Vrzan",
      "speaker_twitter": "dvrzan",
      "talk_description": "VIP and VIPER, while having the same basic components, are quite different in their implementation. They both follow Uncle Bob's Clean Architecture principles and often tend to be mixed up due to their name similarities. This talk will introduce you to the VIP architecture and it’s principles. While specifically made for UIKit apps to fix the MVC problem (Massive View Controllers), we'll take a look at how we could implement it in SwiftUI apps.",
      "talk_type": "normalTalk",
      "title": "VIP, not a VIPER, Clean Swift Architecture"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T05:25:00+00:00",
      "end_at": "2022-01-20T06:00:00+00:00",
      "id": 25,
      "speaker_bio": "An is currently helping to build a digital Bank App at Grab. He loves sharing Swift contents on SwiftVietnam.com,  and reading rocket/space science books.",
      "speaker_company": "Grab",
      "speaker_image": "antran",
      "speaker_name": "An Tran",
      "speaker_twitter": "antranapp",
      "talk_description": "This talk will introduce attendees to the open-source Scenarios framework. The Scenarios framework provides infrastructure for mobile development teams to visually demonstrate the functionalities of any isolated components. It also provides a quick and easy way to create a catalog app of scenarios automatically, which can be continuously distributed to external stakeholders to speed up the development process. This talk will help you to deliver features faster and continuously.",
      "talk_type": "normalTalk",
      "title": "Continuous Features Delivery With Scenario-Driven App Development"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T06:00:00+00:00",
      "end_at": "2022-01-20T06:15:00+00:00",
      "id": 251,
      "speaker_bio": "Omer is an iOS Engineer at Sea with a passion for using lldb to dig into private frameworks, and proselytising functional programming.",
      "speaker_company": "Sea",
      "speaker_image": "omer",
      "speaker_name": "Omer Iqbal",
      "speaker_twitter": "olenhad",
      "talk_description": "Showing images in our applications is often a core experience, and one we often take for granted. This talk will take a dive into the image rendering pipeline on iOS. It will also highlight subtle performance issues you might run into, as well as advice on how to bypass them.",
      "talk_type": "lightningTalk",
      "title": "Optimising image heavy applications"
    },
		{
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T06:15:00+00:00",
      "end_at": "2022-01-20T06:25:00+00:00",
      "id": 252,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "10 minutes break",
      "talk_type": "shortbreak",
      "title": "Short Break ☕️ 🚻 💬"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T06:25:00+00:00",
      "end_at": "2022-01-20T07:00:00+00:00",
      "id": 26,
      "speaker_bio": "Moataz is a developer advocate at Bitrise and has more than 12 years of experience across development and testing roles across industries including agriculture, telecom, healthcare, fashion, and more.",
      "speaker_company": "Bitrise",
      "speaker_image": "moataz",
      "speaker_name": "Moataz Nabil",
      "speaker_twitter": "Moatazeldebsy",
      "talk_description": "Do you want to release your apps faster than ever? Mobile test automation has to be a vital part of your release lifecycle. Learn how you can Scale-up iOS UI Tests using different tools, frameworks, and practices.",
      "talk_type": "normalTalk",
      "title": "Scale-up iOS Automated UI Tests"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T07:00:00+00:00",
      "end_at": "2022-01-20T07:20:00+00:00",
      "id": 38,
      "speaker_bio": "Manuel has been programming since he got his C64 in 1987. He also has been a local politician in Germany and an activist for many years. Whether for the cause of the environment or helping Refugees on the road, Manuel is eager to make an impact. He loves giving ethical talks at technical conferences",
      "speaker_company": "Developer, Environmental Activist",
      "speaker_image": "manuel",
      "speaker_name": "Manuel Carrasco Molina",
      "speaker_twitter": "StuFFmc",
      "talk_description": "Manuel's passion for software development is way older than his passion for the environment. Still, he realises one cannot be more important than the other over the last ten years. In this talk, he will explain why if we don't work together, at every level, the world as we know it, the so-called civilisation will be over in 10 to 15 years. We're the new dinosaurs. Let's try to survive instead of writing the next feature in the next sprint. This sprint — the one for our future — is a way bigger story.",
      "talk_type": "normalTalk",
      "title": "Why Every Developer Should Care About the Environment"
    },
    {
      "activity": "iosconfsg22.day1",
      "start_at": "2022-01-20T07:20:00+00:00",
      "end_at": "2022-01-20T08:20:00+00:00",
      "id": 27,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "Stay tuned with the announcement in the morning!",
      "talk_type": "shortbreak",
      "title": "⭕️ 🔺 🟥   Join the Squid Game themed virtual escape room! Limited seats available, you must RSVP through the links provided. The game requires you to escape in 60 mins + 30 mins briefing."
    },
  
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T04:20:00+00:00",
      "end_at": "2022-01-21T04:40:00+00:00",
      "id": 28,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "Welcome to iOS Conf SG 2022 🎉",
      "talk_type": "registration",
      "title": "Check-in 💻"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T04:40:00+00:00",
      "end_at": "2022-01-21T04:50:00+00:00",
      "id": 29,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "After lunch Boost ⚡️ - The main room is open for questions or showcase what you've done!"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T04:50:00+00:00",
      "end_at": "2022-01-21T05:00:00+00:00",
      "id": 30,
      "speaker_bio": "iOS Tech Lead & Engineer, iOS Subject Matter Expert at GovTech. Apple Distinguished Educator.",
      "speaker_company": "GovTech",
      "speaker_image": "melvin",
      "speaker_name": "Melvin Tan",
      "speaker_twitter": "Melvin_tan",
      "talk_description": "iOS Community in Singapore and plans for 2022.",
      "talk_type": "openingAddress",
      "title": "Opening address"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T05:00:00+00:00",
      "end_at": "2022-01-21T05:35:00+00:00",
      "id": 31,
      "speaker_bio": "Federico Zanetello is an iOS Engineer with strong passion for Swift, minimalism, and design. When he's not busy automating things, he can be found writing at fivestars.blog and/or playing with the latest shiny toys.",
      "speaker_company": "OOZOU",
      "speaker_image": "federico",
      "speaker_name": "Federico Zanetello",
      "speaker_twitter": "zntfdr",
      "talk_description": "Apple's introduction of SwiftUI brought a massive paradigm shift on native iOS and macOS app development. In this talk, he'll share the most important lessons learned from building and shipping a dozen apps in SwiftUI for the past three years, coming from a UIKit developer's perspective and reaching hundreds of thousands of users.",
      "talk_type": "normalTalk",
      "title": "SwiftUI Lessons"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T05:35:00+00:00",
      "end_at": "2022-01-21T06:10:00+00:00",
      "id": 32,
      "speaker_bio": "Marin Todorov is a developer, speaker. Besides crafting code, he enjoys blogging, writing books, teaching",
      "speaker_company": "Indie iOS Dev",
      "speaker_image": "marin",
      "speaker_name": "Marin Todorov",
      "speaker_twitter": "icanzilb",
      "talk_description": "By now you've at least heard about async/await and the new concurrency model in Swift or even better used those in a project. Hopefully so, because a working knowledge will be required for this session to make sense. With the help of the Apple docs, we'll look at synchronous vs asynchronous context, how does the cooperative thread pool manifest itself and, finally, what's with actors?",
      "talk_type": "normalTalk",
      "title": "Swift Concurrency Under the Hood"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T06:10:00+00:00",
      "end_at": "2022-01-21T06:20:00+00:00",
      "id": 33,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "10 minutes discussion",
      "talk_type": "shortbreak",
      "title": "Participant Discussions 💬"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T06:20:00+00:00",
      "end_at": "2022-01-21T06:30:00+00:00",
      "id": 34,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "10 minutes break",
      "talk_type": "shortbreak",
      "title": "Short Break ☕️ 🚻 💬"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T06:30:00+00:00",
      "end_at": "2022-01-21T07:05:00+00:00",
      "id": 35,
      "speaker_bio": "Bas is an iOS and macOS developer with a passion for testability, accessibility and user-centric apps. He cares about communication and collaboration. He likes coffee and watches (a lot).",
      "speaker_company": "WeTransfer",
      "speaker_image": "bas",
      "speaker_name": "Bas Broek",
      "speaker_twitter": "basthomas",
      "talk_description": "Pull request (and their associated reviews) are an integral part of a developer's life. And for good reasons! While some may love them, and others somewhat less so, there's a good chance you're spending quite some time on reviews as part of your project, job, or side project. Therefore, it's crucial to make our pull request experience great. And kind. And welcoming. Bas will share some thoughts on what he has learned about making the pull request experience awesome.",
      "talk_type": "normalTalk",
      "title": "Effective Pull Request Reviews"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T07:05:00+00:00",
      "end_at": "2022-01-21T07:40:00+00:00",
      "id": 36,
      "speaker_bio": "Maciej is a passionate iOS developer that pays much attention to details and code quality. He likes improving things, always looking for better solutions because he's amazed that in the programming world, issues can be solved in 100s of ways. In 2016 he kicked off with his friends the swifting.io blog, where he has been posting his thoughts about various topics.",
			"speaker_company": "Allegro",
			"speaker_image": "maciej",
			"speaker_name": "Maciej Piotrowski",
			"speaker_twitter": "paciej00",
			"talk_description": "Building a feature in an app consisting of hundreds of modules is complicated and leads to dependency management issues. 😱 Learn How to Manage Dependency Injection at Scale.",
			"talk_type": "normalTalk",
			"title": "Dependency Injection at Scale"
    },
		{
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T07:40:00+00:00",
      "end_at": "2022-01-21T07:55:00+00:00",
      "id": 36,
      "speaker_bio": "Jeroen is the iOS Developer Relations Lead at Stream. He is a software developer with 20 years of experience and has focussed on iOS development for over a decade. He also runs a podcast with a focus on iOS developers called AppForce1",
      "speaker_company": "Stream",
      "speaker_image": "jeroen",
      "speaker_name": "Jeroen Leenarts",
      "speaker_twitter": "AppForce1",
      "speaker2_company": "Stream",
      "speaker2_image": "martin",
      "speaker2_name": "Martin Mitrevski",
      "speaker2_bio": "Martin is working at Stream, where he enjoys building a cool new SwiftUI chat SDK, in the open. In his spare time, he blogs at martinmitrevski.com about iOS-related tech, builds indie apps and occasionally speaks at mobile conferences.",
      "speaker2_twitter": "mitrevski",
      "talk_description": "At Stream, Jeroen and Martin created and launched a SwiftUI Chat SDK in the time span of a few months. In this talk, they will share what makes their SDK modern, why they chose to go this route, and what they think you should do in your code to create reusable components with maximum flexibility for the users of your components.",
      "talk_type": "combinedTalk",
      "title": "Building and Launching a Modern SwiftUI SDK"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T07:55:00+00:00",
      "end_at": "2022-01-21T08:05:00+00:00",
      "id": 37,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "10 minutes break",
      "talk_type": "shortbreak",
      "title": "Short Break ☕️ 🚻 💬"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T08:05:00+00:00",
      "end_at": "2022-01-21T08:40:00+00:00",
      "id": 38,
      "speaker_bio": "Vincent works at Worldline and contributes to building great apps for major French banks. He also enjoys sharing about Swift and iOS on his YouTube channel.",
      "speaker_company": "Worldline",
      "speaker_image": "vincent",
      "speaker_name": "Vincent Pradeilles",
      "speaker_twitter": "v_pradeilles",
      "talk_description": "The Mirror API has been part of Swift for years, but it is far from widely known. This is mainly because none of the language key features actually rely on it. However, things have changed lately, as Apple’s Argument Parser library relies on the Mirror API for its core use case. The goal of this talk will be to explain how the Mirror API works and what you can achieve using Mirror API.",
      "talk_type": "normalTalk",
      "title": "Looking Through the Mirror 🪞"
    },
		{
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T08:40:00+00:00",
      "end_at": "2022-01-21T09:15:00+00:00",
      "id": 38,
      "speaker_bio": "Pradnya is an iOS engineer at Rakuten Viki. She loves all things coding, enjoys writing blog posts and reading classic novels.",
      "speaker_company": "Rakuten Viki",
      "speaker_image": "pradnya",
      "speaker_name": "Pradnya Nikam",
      "speaker_twitter": "pradnya_nikam",
      "talk_description": "We have all been there. We are very enthusiastic about writing unit tests when we start working on an application. Fast forward a few months later either unit test coverage is low or their maintenance is a pain. Let's go through common issues with unit tests and some interesting approaches to solve them.",
      "talk_type": "normalTalk",
      "title": "Get More From Your Unit Tests"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T09:15:00+00:00",
      "end_at": "2022-01-21T09:25:00+00:00",
      "id": 39,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "Sponsor hopping game",
      "talk_type": "shortbreak",
      "title": "The Sherlock in You 🕵️‍♂️🕵️‍♀️"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T09:25:00+00:00",
      "end_at": "2022-01-21T09:40:00+00:00",
      "id": 40,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "shortbreak",
      "title": "Grab a Bite 🍽 (15 Mins Break)"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T09:40:00+00:00",
      "end_at": "2022-01-21T09:50:00+00:00",
      "id": 42,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "registration",
      "title": "Kahoot time - Compete for the top 3 spots 🏅! And then ... another history making! Use your favorite Zoom's video filter and swag 😎 📸 "
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T09:50:00+00:00",
      "end_at": "2022-01-21T10:25:00+00:00",
      "id": 43,
      "speaker_bio": "Paul is the author of Hacking with Swift, Pro Swift, Swift Design Patterns, Server-Side Swift, Hacking with macOS, Hacking with watchOS, Hacking with tvOS, Swift Coding Challenges, and more. Suffice it to say, he quite likes Swift. And coffee. (But mostly Swift.) (And coffee.)",
      "speaker_company": "Hacking with Swift",
      "speaker_image": "paul",
      "speaker_name": "Paul Hudson",
      "speaker_twitter": "twostraws",
      "talk_description": "SwiftUI is the fastest way to build apps using all the system-standard components we know and love, but in this talk we're going to stop for a moment and try to build something fun, beautiful, and unlike anything you've seen before.",
      "talk_type": "normalTalk",
      "title": "Special effects with SwiftUI"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T10:25:00+00:00",
      "end_at": "2022-01-21T11:00:00+00:00",
      "id": 44,
      "speaker_bio": "Tunde is an iOS content creator on YouTube, he loves to explore and discuss solutions to complex problems, writing scalable code & discussing his thought process to certain design decisions.",
      "speaker_company": "iOS Educator",
      "speaker_image": "tunde",
      "speaker_name": "Tunde Adegoroye",
      "speaker_twitter": "tundsdev",
      "talk_description": "At least once in your career, you’ve asked yourself that question “I’m sure I’ve built this before?”. In this talk, Tunde will discuss how we can tackle this problem using Modularisation & Swift Package Manager to build small packages that are fit for purpose, testable & are reusable. So you never have to remember to build an extension for setting the translatesAutoresizingMaskIntoConstraints by default again.",
      "talk_type": "normalTalk",
      "title": "Keeping Things Modular With Swift Package Manager"
    },
    {
      "activity": "iosconfsg22.day2",
      "start_at": "2022-01-21T11:00:00+00:00",
      "end_at": "2022-01-21T13:00:00+00:00",
      "id": 45,
      "speaker_bio": "iOS Conf SG Organisers",
      "speaker_company": "iOSConfSG",
      "speaker_image": "organiser",
      "speaker_name": "Organiser",
      "speaker_twitter": "iosconfsg",
      "talk_description": "",
      "talk_type": "shortbreak",
      "title": "Closing + Virtual After-party 🍹 🍻 🧉"
    }
  ]
  
  export default schedule